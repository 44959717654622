import React from 'react'

export default ({ url, style, className = '' }) => (
  <a
    className={className}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Facebook"
    style={style}
  >
    <svg
      width="80"
      height="80"
      viewBox="0 0 474.294 474.294"
      aria-hidden="true"
    >
      <circle style={{ fill: "#3A5A98"}} cx="237.111" cy="236.966" r="236.966"/>
      <path style={{ fill: "#345387" }} d="M404.742,69.754c92.541,92.541,92.545,242.586-0.004,335.134
        c-92.545,92.541-242.593,92.541-335.134,0L404.742,69.754z"/>
      <path style={{ fill: "#2E4D72" }} d="M472.543,263.656L301.129,92.238l-88.998,88.998l5.302,5.302l-50.671,50.667l41.474,41.474
        l-5.455,5.452l44.901,44.901l-51.764,51.764l88.429,88.429C384.065,449.045,461.037,366.255,472.543,263.656z"/>
      <path style={{ fill: "#FFFFFF" }} d="M195.682,148.937c0,7.27,0,39.741,0,39.741h-29.115v48.598h29.115v144.402h59.808V237.276h40.134
        c0,0,3.76-23.307,5.579-48.781c-5.224,0-45.485,0-45.485,0s0-28.276,0-33.231c0-4.962,6.518-11.641,12.965-11.641
        c6.436,0,20.015,0,32.587,0c0-6.623,0-29.481,0-50.592c-16.786,0-35.883,0-44.306,0C194.201,93.028,195.682,141.671,195.682,148.937
        z"/>
    </svg>
  </a>
)