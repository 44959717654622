import React from 'react'

export default ({ url, style, className = '' }) => (
  <a
    className={className}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    aria-label="Email"
    style={style}
  >
    <svg
      width="80"
      height="80"
      viewBox="0 0 64 64"
      aria-hidden="true"
    >
      <g>
        <g>
          <circle style={{fill: "#77B3D4"}} cx="32" cy="32" r="32"/>
        </g>
        <g>
          <g style={{opacity: 0.2}}>
            <path style={{fill: "#231F20"}} d="M52,44c0,2.2-1.8,4-4,4H16c-2.2,0-4-1.8-4-4V24c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4V44z"/>
          </g>
          <g>
            <path style={{fill: "#E0E0D1"}} d="M52,42c0,2.2-1.8,4-4,4H16c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4V42z"/>
          </g>
          <g style={{opacity: 0.2}}>
            <g>
              <path style={{fill: "#231F20"}} d="M35.5,30.2c-1.9-2.1-5.1-2.1-7,0L13,43.2c-0.2,0.2-0.3,0.4-0.5,0.6c0.7,1.3,2,2.2,3.4,2.2h32
                c1.5,0,2.7-0.9,3.4-2.2c-0.1-0.2-0.3-0.4-0.5-0.6L35.5,30.2z"/>
            </g>
          </g>
          <g>
            <g>
              <path style={{fill: "#E0E0D1"}} d="M35.5,32c-1.9-1.9-5.1-1.9-7,0L13,43.5c-0.2,0.2-0.3,0.3-0.5,0.5c0.7,1.2,2,1.9,3.4,1.9h32
                c1.5,0,2.7-0.8,3.4-1.9c-0.1-0.2-0.3-0.3-0.5-0.5L35.5,32z"/>
            </g>
          </g>
          <g style={{opacity: 0.2}}>
            <g>
              <path style={{fill: "#231F20"}} d="M12.6,20.2c0.7-1.3,2-2.2,3.4-2.2h32c1.5,0,2.7,0.9,3.4,2.2c-0.1,0.2-0.3,0.4-0.5,0.6l-15.4,13
                c-1.9,2.1-5.1,2.1-7,0L12.6,20.2z"/>
            </g>
          </g>
          <g>
            <g>
              <path style={{fill: "#FFFFFF"}} d="M28.5,32c1.9,1.9,5.1,1.9,7,0L51,20.5c0.2-0.2,0.3-0.3,0.5-0.5c-0.7-1.2-2-1.9-3.4-1.9H16
                c-1.5,0-2.7,0.8-3.4,1.9c0.1,0.2,0.3,0.3,0.5,0.5L28.5,32z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </a>
)